import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import { GRAPHS_WIDTH, MOBILE_GRAPHS_WIDTH, GRAPHS_HEIGHT } from '../Constants'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const TicketSalesOverTimeChart = ({ eventData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  if (!eventData) {
    return <Typography variant='h6'>No data available</Typography>
  }

  const chartData = Object.keys(eventData.dateAnalytics).map(date => ({
    date,
    totalTickets: eventData.dateAnalytics[date].totalTickets || 0
  }))

  return (
    <div>
      <Typography variant='h6'>Ticket Sales Over Time</Typography>
      <LineChart
        width={isMobile ? GRAPHS_WIDTH : MOBILE_GRAPHS_WIDTH}
        height={GRAPHS_HEIGHT}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type='monotone' dataKey='totalTickets' stroke='#8884d8' />
      </LineChart>
    </div>
  )
}

export default TicketSalesOverTimeChart
