import Cookies from 'js-cookie'
// I USE IT FOR EASIER LOCAL DEV - JUST KEEP IT 
const localDevToken = ''
export const getTicketsInfo = async (
  lang = 'ru',
  page = '0',
  pagesize = '25',
  search = ''
) => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets?populate[event][populate]=*&locale=${lang}&sort[0]=id:desc&pagination[page]=${page}&pagination[pageSize]=${pagesize}${search}`,
      options
    ).then(r => r.json())

    const response = {
      data: req.data || [],
      total: req.meta.pagination.total || 0
    }
    return response
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getEventsAnalyticsInfo = async (
  lang = 'ru',
  page = '0',
  pagesize = '25',
  search = ''
) => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/events/analytics?locale=${lang}&sort[0]=id:desc&pagination[page]=${page}&pagination[pageSize]=${pagesize}${search}`,
      options
    ).then(r => r.json())

    const response = {
      data: req.data,
      total: req.total
    }
    return response
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getTicketsChartsInfo = async (lang = 'ru', startDate, endDate) => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/analytics?locale=${lang}&startDate=${startDate}&endDate=${endDate}`,
      options
    ).then(r => r.json())

    return req.data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getEventsInfo = async (lang = 'ru') => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
    const req = await fetch(
      `${
        process.env.REACT_APP_API_URL
      }/api/events?locale=${lang}&&filters[date][$gte]=${new Date().toISOString()}`,
      options
    ).then(r => r.json())

    return req.data || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getEventInfo = async (id, lang = 'ru') => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/events/${id}?populate=*&locale=${lang}`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(err.message)
      })

    return req.data || {}
  } catch (err) {
    throw new Error(err.message)
  }
}

export const getLimitedEventInfo = async (id, lang = 'ru') => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/events/${id}?populate[zone][populate]=*&populate[poster][populate]=*&locale=${lang}`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(err.message)
      })

    return req.data || {}
  } catch (err) {
    throw new Error(err.message)
  }
}

export const bookTicket = async requestData => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      data: {
        ...requestData
      }
    })
  }
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/book`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(err.message)
      })

    return req.data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const sendTickets = async (id, paid) => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      },
      body: JSON.stringify({
        paid: paid
      })
    }
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/${id}/send`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
      })
      .catch(err => {
        throw new Error(err.message)
      })
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getPromocodeInfo = async (id, lang = 'ru') => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/promocodes/${id}?populate=*&locale=${lang}`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(err.message)
      })

    return req.data || {}
  } catch (err) {
    throw new Error(err.message)
  }
}

export const getTicketInfo = async (id, lang = 'ru') => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      }
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/${id}?populate=*&locale=${lang}`,
      options
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(err.message)
      })

    return req.data || {}
  } catch (err) {
    throw new Error(err.message)
  }
}

export const updateTicketInfo = async id => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      },
      body: JSON.stringify({
        data: {
          paid: true,
          Scanned: true
        }
      })
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/${id}`,
      options
    ).then(r => r.json())

    return req.data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const updateTicketStatuses = async (id, status) => {
  try {
    const token = Cookies.get('token')
    const options = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}` // notice the Bearer before your token
      },
      body: JSON.stringify({
        data: {
          [status]: true
        }
      })
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/${id}`,
      options
    ).then(r => r.json())

    return req.data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getSingleTicket = async uuid => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json'
      }
    }

    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tickets/${uuid}/info`,
      options
    ).then(r => r.json())

    return req
  } catch {
    return null
  }
}
