import React from 'react'
import { makeStyles } from '@mui/styles'
import TelegramIcon from '@mui/icons-material/Telegram'
import InstagramIcon from '@mui/icons-material/Instagram'
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
    maxWidth: '800px',
    position: 'fixed',
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px 5px 0 0',
    bottom: 0,
    width: '100%',
    minHeight: '50px',
    margin: 'auto',
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  content: {
    marginTop: '9px',
    paddingBottom: '9px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  contentItem: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none'
  }
}))
const Footer = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
            <span className={classes.contentItem}><TelegramIcon /> <a href="tel:+359898412303" className={classes.link}> +359 89 841 2303 </a></span>
            <span className={classes.contentItem}><TelegramIcon /> <a href="tel:+359878150284" className={classes.link}> +359 87 815 0284 </a></span>
            <span className={classes.contentItem}><InstagramIcon /> <a href="https://www.instagram.com/schengen_standup" className={classes.link}>{''}Instagram</a></span>
            <span className={classes.contentItem}><TelegramIcon /> <a href="https://t.me/schengen_standup" className={classes.link}>{''}Telegram</a></span>

        </div>
      </div>
    </>
  )
}

export default Footer
