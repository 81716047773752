import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

export const NavMenuElements = [
  {
    id: 1,
    menuName: 'Tickets Data',
    menuIcon: <LocalActivityIcon />,
    menuLink: '/tickets',
    menuTitle: 'All love to see some tickets'
  },
  {
    id: 2,
    menuName: 'Tickets Analytics',
    menuIcon: <AnalyticsIcon />,
    menuLink: '/analytics',
    menuTitle: 'Graphs and more'
  },
  {
    id: 3,
    menuName: 'Scan Page',
    menuIcon: <QrCodeScannerIcon />,
    menuLink: '/scan',
    menuTitle: ''
  }
]
