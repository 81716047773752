import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { getContent } from '../requests/store/GetContentReducrers'
import { loggedInUser } from '../requests/store/LoginReducers'

import { showNotification } from '../components/notifications/store/notificationReducers'
// import { changeCurrentLanguage } from "../components/navbar/store/change_language/ChangeLanguageReducers"
import { getCurrentPage } from "../components/navbar/store/current_page/CurrentPageReducers"
const persistConfig = {
  key: 'BGStandup',
  whitelist: [
    'getContent',
    'getCurrentPage',
    'loggedInUser',
  ],
  storage
}

const rootReducer = combineReducers({
  getContent,
  loggedInUser,
  getCurrentPage,
  showNotification
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
)
const persistor = persistStore(store)
export { store, persistor }
