import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import TablePagination from '@mui/material/TablePagination'

import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import {
  sendTickets,
  getTicketsInfo,
  updateTicketStatuses
} from '../../requests'
import { LogoutAction } from '../../requests/store/LoginActions'

export default function TicketsTable () {
  const [rows, setRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [searchBy, setSearchBy] = useState('reference_number')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeSearchBy = event => {
    setSearchBy(event.target.value)
  }

  const handleSearch = async event => {
    setSearch(event.target.value)
  }

  const handlePaid = async row => {
    if (row.attributes.ticket_send) {
      await paySingleTicket(row.id)
    } else {
      await handleTicketSend(row, true)
    }
  }

  const paySingleTicket = async id => {
    await updateTicketStatuses(id, 'paid')
    setRows(
      rows.map(row => {
        if (row.id !== id) {
          return row
        }

        const newRow = { ...row }
        newRow.attributes = {
          ...row.attributes,
          paid: true
        }
        return newRow
      })
    )
  }

  const handleTicketSend = async (row, paid) => {
    await sendTickets(row.id, paid)
    setRows(
      rows.map(oldRow => {
        if (
          oldRow.attributes.holder_phone !== row.attributes.holder_phone ||
          oldRow.attributes.holder_email !== row.attributes.holder_email
        ) {
          return oldRow
        }

        const newRow = { ...oldRow }
        newRow.attributes = { ...oldRow.attributes }
        newRow.attributes.ticket_send = true
        if (paid) {
          newRow.attributes.paid = true
        }
        return newRow
      })
    )
  }

  const dispatch = useDispatch()
  useEffect(() => {
    const cookie = Cookies.get('token')
    if (!cookie) {
      dispatch(LogoutAction())
    }
    const updateTableInfo = async () => {
      let searchString
      if (search !== '') {
        searchString = `&filters[${searchBy}][$containsi]=${search}`
      }

      const response = await getTicketsInfo(
        'ru',
        page + 1,
        rowsPerPage,
        searchString
      )

      setRows(() => response.data)
      setTotalRows(() => response.total)
    }
    updateTableInfo()
  }, [dispatch, page, rowsPerPage, search, searchBy])

  return (
    <>
      <div>
        <InputLabel style={{ margin: '10px' }}>Search by</InputLabel>
        <Select
          style={{ margin: '10px' }}
          value={searchBy}
          label='searchBy'
          onChange={handleChangeSearchBy}
        >
          <MenuItem value={'reference_number'}>Reference Number</MenuItem>
          <MenuItem value={'ticket_holder'}>Holder name</MenuItem>
          <MenuItem value={'holder_phone'}>Phone Number</MenuItem>
          <MenuItem value={'holder_email'}>Holder Email</MenuItem>
        </Select>
        <TextField
          style={{ margin: '10px' }}
          id='outlined-basic'
          label='Search'
          variant='outlined'
          value={search}
          onChange={handleSearch}
        />
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Reference Number</TableCell>
              <TableCell>Holder Name</TableCell>
              <TableCell align='right'>Event</TableCell>
              <TableCell align='right'>Paid</TableCell>
              <TableCell align='right'>Phone</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Zone</TableCell>
              <TableCell align='right'>Place</TableCell>
              <TableCell align='right'>Download Ticket</TableCell>
              <TableCell align='right'>Ticket Send</TableCell>
              <TableCell align='right'>Ticket Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow
                key={row?.attributes?.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row?.attributes?.reference_number}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {row?.attributes?.ticket_holder}
                </TableCell>
                <TableCell align='right'>
                  {row?.attributes?.event?.data?.attributes?.name}
                </TableCell>
                <TableCell align='right'>
                  {row?.attributes?.paid ? 'paid' : 'not paid'}{' '}
                </TableCell>
                <TableCell align='right'>
                  {row?.attributes?.holder_phone}
                </TableCell>
                <TableCell align='right'>
                  {row?.attributes?.holder_email}
                </TableCell>
                <TableCell align='right'>{row?.attributes?.zone}</TableCell>
                <TableCell align='right'>{row?.attributes?.Place}</TableCell>

                <TableCell align='right'>
                  {row?.attributes?.ticket_send ? (
                    ''
                  ) : (
                    <a
                      download={`${row?.attributes?.ticket_holder}.pdf`}
                      href={`${process.env.REACT_APP_API_URL}/api/tickets/${row?.id}/pdf`}
                    >
                      Download
                    </a>
                  )}
                </TableCell>
                <TableCell align='right'>
                  <Button
                    variant='outlined'
                    style={{ margin: '10px' }}
                    onClick={() => handleTicketSend(row, false)}
                    disabled={row?.attributes?.ticket_send}
                  >
                    Send
                  </Button>
                </TableCell>
                <TableCell align='right'>
                  <Button
                    variant='outlined'
                    style={{ margin: '10px' }}
                    onClick={() => handlePaid(row)}
                    disabled={row?.attributes?.paid}
                  >
                    Paid
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
