import { red } from '@mui/material/colors'
export const themesConfig = {
  palette: {
    type: 'dark',
    primary: {
      light: '#2b2b2b',
      main: '#161616',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff'
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    error: red
  },
  props: {
    MuiSvgIcon: {
      htmlColor: '#FFFFFF'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    fontFamily: 'IBM Plex Sans'
  }
}

export default themesConfig
