import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { isDateBeforeToday } from '../../helpers/date'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { getPromocodeInfo } from '../../requests'
import {
  setNotificationMessage,
  setShowNotificationMessage
} from '../../components/notifications/store/notificationActions'
const useStyles = makeStyles(theme => ({
  checkout: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: '20px'
  },
  form: {
    width: '300px',
    marginTop: theme.spacing(2)
  },
  promocode: {
    display: 'flex'
  },
  promocodeButton: {
    padding: '10px 23px !important',
    margin: '15px 5px 10px 20px !important'
  },
  submitButton: {
    margin: '10px 0 40px 0'
  },
  paddingTop: {
    paddingTop: '10px'
  }
}))

const OrderSecondStep = ({
  setSum,
  eventId,
  setPromocodeID,
  isFreePromo,
  setIsFreePromo,
  ticketsAmount,
  totalSum,
  rows,
  discountAmount,
  setDiscountAmount,
  handleSubmit,
  touched,
  errors,
  handleBlur,
  values,
  handleChange,
  isValid,
  dirty,
  loading
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const applyDiscount = async () => {
    try {
      const promocode = await getPromocodeInfo(values.promocode)
      const dateValid = isDateBeforeToday(
        new Date(promocode.attributes.work_until)
      )

      if (!dateValid) {
        dispatch(setNotificationMessage('Промокод невалиден'))
        dispatch(setShowNotificationMessage(true))
        return
      }

      const validEvent = promocode.attributes.events?.data?.some(
        e => e.id === eventId
      )
      if (!validEvent) {
        dispatch(setNotificationMessage('Промокод невалиден'))
        dispatch(setShowNotificationMessage(true))
        return
      }

      const isFreeDiscount = promocode.attributes.type === 'FREE'
      if (isFreeDiscount) {
        setPromocodeID(promocode.id)
        setIsFreePromo(() => true)
        return
      }

      setDiscountAmount(promocode.attributes.discount_amount)
      setIsFreePromo(() => false)
      setPromocodeID(promocode.id)
      let sum = 0
      rows?.zone.forEach(el => {
        sum +=
          ticketsAmount[el.zone] *
          (el.price - promocode.attributes.discount_amount)
      })
      setSum(() => sum)
    } catch {
      setDiscountAmount(0)
      setPromocodeID(null)
      setIsFreePromo(() => false)

      let sum = 0
      rows?.zone.forEach(el => {
        sum += ticketsAmount[el.zone] * el.price
      })
      setSum(() => sum)
      dispatch(setNotificationMessage('Промокод невалиден'))
      dispatch(setShowNotificationMessage(true))
    }
  }

  let paymentNote = <></>;

  if (rows?.zone.some(el => el.zone !== 'FREE')) {
    if (rows?.prepaidDiscount) {
      paymentNote = (
        <>
          <Typography variant='subtitle2' align='left'>* Оплата производится после</Typography>
          <Typography variant='subtitle2' align='left'>
            бронирования и получения билетов
          </Typography>
          <Typography variant='subtitle2' align='left'>* При оплате до события (не на входе) скидка 5лв</Typography>
        </>
      )
    } else {
      paymentNote = (
        <>
          <Typography variant='subtitle2' align='left'>* Оплата производится после</Typography>
          <Typography variant='subtitle2' align='left'>
            бронирования и получения билетов
          </Typography>
        </>
      )
    }
  }

  return (
    <div className={classes.checkout}>
      <Button variant='outlined' onClick={() => setSum(0)}>
        Редактировать Билеты
      </Button>
      <Divider>.</Divider>
      <Typography variant='h5'>
        Cумма: {isFreePromo ? 'Бесплатно' : `${totalSum} лев`}
      </Typography>
      <Divider>.</Divider>
      {paymentNote}
      {discountAmount > 0 || isFreePromo ? (
        <Typography variant='caption'>Промокод добавлен</Typography>
      ) : (
        <></>
      )}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          required
          margin='normal'
          name='ticket_holder'
          type='text'
          error={touched.ticket_holder && Boolean(errors.ticket_holder)}
          helperText={touched.ticket_holder ? errors.ticket_holder : ''}
          label='Ваше имя:'
          onBlur={handleBlur}
          value={values.ticket_holder}
          onChange={handleChange}
          variant='outlined'
          autoComplete='cc-name'
          fullWidth
        />
        <TextField
          required
          margin='normal'
          name='holder_email'
          type='email'
          error={touched.holder_email && Boolean(errors.holder_email)}
          helperText={touched.holder_email ? errors.holder_email : ''}
          label='Email:'
          onBlur={handleBlur}
          value={values.holder_email}
          onChange={handleChange}
          variant='outlined'
          autoComplete='email'
          fullWidth
        />
        <TextField
          required
          margin='normal'
          name='holder_phone'
          type='tel'
          error={touched.holder_phone && Boolean(errors.holder_phone)}
          helperText={touched.holder_phone ? errors.holder_phone : ''}
          label='Номер телефона:'
          onBlur={handleBlur}
          value={values.holder_phone}
          onChange={handleChange}
          variant='outlined'
          autoComplete='tel'
          fullWidth
        />
        <TextField
          margin='normal'
          name='nickname'
          type='text'
          error={touched.nickname && Boolean(errors.nickname)}
          helperText={touched.nickname ? errors.nickname : ''}
          label='Телеграм (не обязательно)'
          onBlur={handleBlur}
          value={values.nickname}
          onChange={handleChange}
          variant='outlined'
          autoComplete='tel'
          fullWidth
        />
        <Typography variant='caption'>
          * После ввода промокода, сначала нажмите "Применить", чтобы он был
          привязан к вашему заказу.
        </Typography>
        <span className={classes.promocode}>
          <TextField
            margin='normal'
            name='promocode'
            type='text'
            label='Промокод:'
            onBlur={handleBlur}
            value={values.promocode}
            onChange={handleChange}
            variant='outlined'
            fullWidth
          />
          <Button
            variant='contained'
            color='primary'
            className={classes.promocodeButton}
            onClick={() => applyDiscount()}
          >
            Применить
          </Button>
        </span>
        <Divider>.</Divider>
        <FormControlLabel
          control={<Checkbox checked={values.consent} />}
          label='Подписываясь на нашу рассылку, я соглашаюсь получать информацию о новостях, акциях и специальных предложениях.'
          name='consent'
          componentsProps={ {typography : {variant: 'caption', align: 'left'} }}
          onChange={handleChange}
        />
        <div className={classes.submitButton}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={!(isValid && dirty && !loading)}
          >
            Забронировать билеты
          </Button>
          <Typography variant='subtitle2' align='left' className={classes.paddingTop}>
            * Пожалуйста, убедитесь, что вы проверили свой email. После
            бронирования мы отправим вам билеты, а оплата будет осуществляться
            после их получения.
          </Typography>
        </div>
      </form>
    </div>
  )
}

export default OrderSecondStep
