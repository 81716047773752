import * as constants from './LoginConstants'
import {
  setNotificationMessage,
  setNotificationSeverity,
  setShowNotificationMessage
} from '../../components/notifications/store/notificationActions'
import Cookies from 'js-cookie'

export const LoginAction = params => dispatch => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': `application/json` },
    body: JSON.stringify(params)
  }
  fetch(`${process.env.REACT_APP_API_URL}/api/auth/local`, requestOptions)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        throw new Error(json.error.message)
      }
      let role = 'user'
      Cookies.set('token', json.jwt, { expires: 1, secure: true })

      dispatch({ type: constants.REQUEST_SIGNIN_SUCCESS, payload: json.user })
      dispatch({ type: constants.SET_USER_ROLE, payload: role })
    })
    .catch(error => {
      dispatch(setNotificationMessage(error.message))
      dispatch(setShowNotificationMessage(true))
    })
}

export const LogoutAction = () => (dispatch) => {
  Cookies.remove('token')
  dispatch({ type: constants.REQUEST_SIGNOUT_SUCCESS })
}
