import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'

const useStyles = makeStyles(theme => ({
  tickets: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  ticket: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '10px',
    minWidth: '340px',
    maxWidth: '340px',
    margin: '10px',
    borderRadius: '5px'
  },
  soldout: {
    position: 'absolute',
    marginLeft: '220px',
    borderRadius: '15px',
    padding: '7px',
    background: theme.palette.error.main,
  },
  prices: {
    margin: '20px 0  0  0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  submitButton: {
    margin: '10px 0 40px 0'
  }
}))
const OrderFirstStep = ({
  rows,
  eventIsInPast,
  addToValue,
  ticketsAmount,
  subtractFromValue,
  handleClick
}) => {
  const classes = useStyles()
  return (
    <>
      <Typography
        variant='h4'
        style={{ whiteSpace: 'pre-line', padding: '10px' }}
      >
        Билеты:
      </Typography>
      <div className={classes.tickets}>
        {rows?.zone?.map(el => (
          <div className={classes.ticket} key={el.zone}>
            {el.tickets_avaliable <= 0 && (
              <span className={classes.soldout}>Распродано</span>
            )}
            <Typography variant='h5'>{el.display_text || el.zone}</Typography>
            <Typography>{rows.name}</Typography>
            <div className={classes.prices}>
              <Typography variant='h5'>{el.price} лев</Typography>
              <div>
                <IconButton
                  aria-label='add'
                  disabled={
                    el.tickets_avaliable === 0 ||
                    eventIsInPast ||
                    ticketsAmount[el.zone] >= el.tickets_avaliable
                  }
                  onClick={() => addToValue(el.zone)}
                >
                  <AddIcon />
                </IconButton>
                <Input
                  type='number'
                  disabled={el.tickets_avaliable === 0 || eventIsInPast}
                  style={{ width: '40px' }}
                  defaultValue='0'
                  value={ticketsAmount[el.zone]}
                  label='ticketsAmount'
                />
                <IconButton
                  disabled={el.tickets_avaliable === 0 || eventIsInPast}
                  aria-label='delete'
                  onClick={() => subtractFromValue(el.zone)}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.submitButton}>
        <Button
          variant='contained'
          onClick={() => handleClick(rows?.zone)}
          disabled={Object.values(ticketsAmount).every(obj => obj === 0)}
        >
          Продолжить
        </Button>
      </div>
    </>
  )
}

export default OrderFirstStep
