import React, { useState, useEffect } from 'react'

import { getSingleTicket } from '../../requests'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import logo from '../../assets/logo.png'
import Footer from '../homePage/Footer'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '90px'
    }
  },
  imageCentered: {
    margin: '10px 0 0 0'
  },
}))

const SingleTicket = props => {
  const [ticketData, setTicketData] = useState(null)
  const classes = useStyles()
  const uuid = props.match.params.slug
  const eventLink = `/events/${ticketData?.event?.websitePath}`

  useEffect(() => {
    getSingleTicket(uuid).then((td) => {
      setTicketData(td)
    })
  }, [])


  return (
    <>
      <div className={classes.root}>
        <div className={classes.imageCentered}>
          <img src={logo} alt='logo' width='270px' />
        </div>
        <Typography
          variant='h5'
          style={{ whiteSpace: 'pre-line', padding: '40px 40px 20px 40px' }}
        >
          Билет: {uuid}<br/>
          Событие: <a href={eventLink}>{ticketData?.event?.name}</a><br/>
          Категория билета: {ticketData?.zone}<br/>
          Цена: {ticketData?.price} лв<br/>
          Статус: {ticketData?.paid ? 'оплачен' : 'не оплачен'}<br/>
        </Typography>
        <Typography
          variant='body1'
          style={{ whiteSpace: 'pre-line', padding: '20px 40px 40px 40px' }}
        >
          Поздравляем с успешным сканированием билета! Теперь вы знаете, что делать в трудную минуту, или если вам нечем заняться, и под рукой есть только билет на один из наших эвентов!<br/>
          {ticketData && !ticketData.paid ? 'Чтобы забронировать наболее удобные места, вы всегда можете написать нам в Telegram/WhatsApp/Viber.' : ''}
        </Typography>
      </div>
      <Footer />
    </>
  )
}

export default SingleTicket
