import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import { GRAPHS_WIDTH, MOBILE_GRAPHS_WIDTH, GRAPHS_HEIGHT } from '../Constants'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const TicketSalesChart = ({ data }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  if (Object.keys(data).length === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h6'>Total Revenue by Event</Typography>
        <Typography>No data available</Typography>
      </div>
    )
  }
  const chartData = Object.keys(data).map(eventId => ({
    name: data[eventId].name,
    totalRevenue: data[eventId].totalRevenue
  }))

  return (
    <div>
      <Typography variant='h6'>Total Revenue by Event</Typography>
      <BarChart
        width={isMobile ? GRAPHS_WIDTH : MOBILE_GRAPHS_WIDTH}
        height={GRAPHS_HEIGHT}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='totalRevenue' fill='#8884d8' />
      </BarChart>
    </div>
  )
}

export default TicketSalesChart
