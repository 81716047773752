import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'

import logo from '../../assets/logo.png'
import { getEventsInfo } from '../../requests'
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '800px',
    minHeight: '100vh',
    paddingBottom: '90px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '110px'
    }
  },
  imageCentered: {
    margin: '10px 0 0 0'
  },
  description: {
    padding: '0px 20px 30px 20px',
    textAlign: 'center'
  },
  events: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  event: {
    background: theme.palette.primary.main,
    padding: '20px',
    minWidth: '350px',
    fontSize: '21px',
    color: theme.palette.primary.contrastText,
    borderRadius: '10px',
    cursor: 'pointer',
    margin: '10px',
    textAlign: 'center'
  },
  eventDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  dateColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    minWidth: '150px',
    marginRight: '15px',
    paddingRight: '25px',
    maxWidth: '150px',
    textAlign: 'start',
    borderRight: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRight: '0px solid white',
      borderBottom: '1px solid white',
      textAlign: 'center',
      minWidth: '100%',
      maxWidth: '100%',
      marginRight: '0px',
      paddingRight: '0px',
      marginBottom: '5px',
      paddingBottom: '5px'
    }
  },
  nameColumn: {
    alignContent: 'center',
    width: '100%'
  },
  postponedEvent: {
    background: theme.palette.error.main,
    padding: '5px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      flex: 'auto'
    }
  }
}))

const HomePage = () => {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      const data = await getEventsInfo()
      const newRows = data
        .map(el => el.attributes)
        .sort(function (a, b) {
          var c = new Date(a.date)
          var d = new Date(b.date)
          return c - d
        })
      setRows(() => newRows)
    }
    fetchData()
  }, [])
  const handleClick = event => {
    history.push(`/events/${event?.websitePath}`)
  }
  const handleRedirect = event => {
    window.location.href = event?.websitePath
  }
  const capitalizeFirstLetter = string => {
    return string[0].toUpperCase() + string.slice(1)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.imageCentered}>
          <img src={logo} alt='logo' width='270px' />
        </div>
        <Typography variant='h5' className={classes.description}>
          "Шенген" - стендап клуб в Болгарии, предлагающий выступления
          русскоязычных комиков
        </Typography>
        <Typography variant='h4'>Следующие события:</Typography>
        <div className={classes.events}>
          {rows.map(event => (
            <div
              className={classes.event}
              key={event.websitePath}
              onClick={() =>
                event.isRedirect ? handleRedirect(event) : handleClick(event)
              }
            >
              <div className={classes.eventDescription}>
                <div className={classes.dateColumn}>
                  {event.postponed ? (
                    <span className={classes.postponedEvent}>Перенесен</span>
                  ) : (
                    <>
                      {new Date(event.date)
                        .toLocaleDateString('ru-RU', {
                          timeZone: 'Europe/Sofia',
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric'
                        })
                        .split(',')
                        .map(el => (
                          <div>{capitalizeFirstLetter(el)}</div>
                        ))}
                    </>
                  )}
                </div>

                <div className={classes.nameColumn}>{event.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default HomePage
