import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import TicketsCharts from '../../components/analytics'

import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import { getEventsAnalyticsInfo } from '../../requests'
import { LogoutAction } from '../../requests/store/LoginActions'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '650px'
  },
  imageCentered: {
    margin: '10px 0 0 0'
  }
}))

export default function TicketsAnalytics () {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = async event => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    const cookie = Cookies.get('token')
    if (!cookie) {
      dispatch(LogoutAction())
    }
    const updateTableInfo = async () => {
      let searchString = ''
      if (search !== '') {
        searchString = `&filters[name][$containsi]=${search}`
      }
      const response = await getEventsAnalyticsInfo(
        'ru',
        page + 1,
        rowsPerPage,
        searchString
      )

      setTotalRows(() => response.total)
      setRows(() => Object.entries(response.data))
    }
    updateTableInfo()
  }, [dispatch, page, rowsPerPage, search])

  const categoriesSet = new Set();
  rows?.forEach(([name, row]) => {
    Object.entries(row.zones || {}).forEach(([zone, val]) => {
      categoriesSet.add(zone);
    })
  })

  const categories = [...categoriesSet].sort();

  return (
    <>
      <TicketsCharts />
      <TextField
        label='Search by name'
        style={{ marginTop: '10px',  }}
        variant='outlined'
        value={search}
        onChange={handleSearch}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <TableContainer component={Paper} className={classes.root}>
        <Table sx={{ minWidth: 650 }} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Event Date</TableCell>
              <TableCell>Data not on our side</TableCell>
              <TableCell>Total tickets reserved</TableCell>
              <TableCell>Total tickets Left</TableCell>
              <TableCell>Paid tickets</TableCell>
              <TableCell>Unpaid tickets</TableCell>
              <TableCell>Free tickets</TableCell>
              <TableCell>Sum of paid Tickets</TableCell>
              <TableCell>Sum of Unpaid tickets</TableCell>
              <TableCell>Total Sum (free not included)</TableCell>
              {categories.map(
                zone => (
                  <TableCell key={zone}>{zone} zone tickets</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row[0]}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {new Date(row[1].eventDate)?.toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {row[1].isRedirect ? 'not on ours' : 'on ours'}
                </TableCell>

                <TableCell>{row[1].totalTickets}</TableCell>
                <TableCell>{row[1].totalTicketsLeft}</TableCell>
                <TableCell>{row[1].paid}</TableCell>
                <TableCell>{row[1].unpaid}</TableCell>
                <TableCell>{row[1].freeTickets}</TableCell>
                <TableCell>{row[1].sumPaid}</TableCell>
                <TableCell>{row[1].sumUnpaid}</TableCell>
                <TableCell>{row[1].total}</TableCell>
                {categories.map(zone => (
                  <TableCell key={zone}>{row[1].zones[zone]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
