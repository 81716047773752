import React from 'react'
import { useSelector } from 'react-redux'
import Drawer from '../../components/navbar/Drawer'
import TicketsAnalytics from './TicketsAnalytics'
import TicketsTable from './TicketsTable'
import Scan from '../scan/QRScanner'

export default function InfoPage () {
  const state = useSelector(state => ({
    currentPage: state.getCurrentPage.currentPage
  }))

  const PAGES = {
    '/analytics': <TicketsAnalytics />,
    '/tickets': <TicketsTable />,
    '/scan': <Scan />
  }

  return (
    <Drawer>
      {PAGES[state.currentPage]}
    </Drawer>
  )
}
