import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import { LoginAction } from '../../requests/store/LoginActions'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    background: theme.palette.primary.main,
    margin: theme.spacing(1)
  },
  loginForm: {
    padding: theme.spacing(2),
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '300px',
    marginTop: theme.spacing(2),
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.main
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const state = useSelector((state) => ({
    loggedIn: state.loggedInUser.loggedIn,
  }));
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  // form
  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      identifier: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
      password: Yup.string().required('Password is required')
    })
  })
  useEffect(() => {
    const cookie = Cookies.get('token')
    if (cookie) {
      history.push('/scan')
    }
  }, [state.loggedIn])

  const { values, touched, errors, handleChange, handleBlur, isValid, dirty } =
    formik
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(LoginAction(values))
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.paper}>
          <div className={classes.loginForm}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                required
                margin='normal'
                name='identifier'
                type='identifier'
                error={touched.identifier && Boolean(errors.identifier)}
                helperText={touched.identifier ? errors.identifier : ''}
                label='Email'
                onBlur={handleBlur}
                value={values.identifier}
                onChange={handleChange}
                variant='outlined'
                className={classes.formControl}
                autoComplete='email'
                fullWidth
              />
              <TextField
                variant='outlined'
                required
                fullWidth
                margin='normal'
                className={classes.formControl}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password ? errors.password : ''}
                onBlur={handleBlur}
                onChange={handleChange}
                name='password'
                label='Password'
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() =>
                          setShowPassword(passwordStatus => !passwordStatus)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={!(isValid && dirty)}
              >
                Sign In
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
