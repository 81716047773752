import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '200px',
    minWidth: '200px',
    padding: '20px',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.background.paper,
    borderRadius: '5px',
    margin: '20px 10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
      minWidth: '150px'
    }
  }
}))
const Card = ({ displayData, displayText }) => {
  const classes = useStyles()
  const [showData, setShowData] = useState({
    data: '',
    description: ''
  })
  useEffect(() => {
    setShowData(() => {
      return {
        data: displayData,
        description: displayText
      }
    })
  }, [displayData, displayText])
  return (
    <div className={classes.root}>
      <Typography>{showData.description}:</Typography>
      <Typography variant='h4'>{showData.data}</Typography>
    </div>
  )
}

export default Card
