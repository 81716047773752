import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    flexDirection: 'column',
    padding: '20px 20px 0px 20px',
    marginBottom: '10px',
    borderRadius: '5px',
    maxWidth: '500px'
  },
  submitButton: {
    alignSelf: 'center',
    padding: '20px'
  }
}))
const OrderMoreTickets = ({ setTicketOrdered, ticketAmount }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <Typography
          variant='h6'
          style={{ padding: '10px', textAlign: 'center' }}
        >
          Ваш заказ на {ticketAmount} билет/а успешно принят.
        </Typography>
        <Typography
          variant='body1'
          style={{ padding: '2px 10px' }}
        >
          Пожалуйста проверьте указанную почту.
        </Typography>
        <Typography
          variant='body2'
          style={{ padding: '2px 10px' }}
        >
          Если вы не получили билет, пожалуйста свяжитесь с нами.
        </Typography>
      </div>

      <div className={classes.submitButton}>
        <Button
          variant='contained'
          onClick={() => setTicketOrdered(() => false)}
        >
          Хочу Больше Билетов!!!
        </Button>
      </div>
    </div>
  )
}

export default OrderMoreTickets
