import * as constants from './GetContentConstants'

export const setContent = pages => ({
  type: constants.GET_CONTENT_SUCCESS,
  payload: pages
})

export const setLoading = () => ({
  type: constants.GET_CONTENT_LOADING
})

export const setLoadingError = () => ({
  type: constants.GET_CONTENT_LOADING
})
