import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { LogoutAction } from '../../requests/store/LoginActions'
import { getTicketsChartsInfo } from '../../requests'
import TicketSalesChart from './charts/TicketSalesChart'
import TicketSalesOverTimeChart from './charts/TicketSalesOverTimeChart'
import PaidVsUnpaidTicketsChart from './charts/PaidVsUnpaidTicketsChart'
import SalesByZoneChart from './charts/SalesByZoneChart'
import TicketSalesHeatmap from './charts/TicketSalesHeatmap'
import Card from './Card'

dayjs.extend(timezone)
dayjs.extend(utc)

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    margin: 'auto',
    // margin: '20px 10px',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: 'unset'
    }
  },
  datePicker: {
    maxWidth: '350px',
    marginBottom: '40px'
  },
  selectors: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  charts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}))
const TicketsCharts = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [pickerDate, setPickerDate] = React.useState([
    dayjs.utc().subtract(30, 'day'),
    dayjs.utc()
  ])
  const [selectedEvent, setSelectedEvent] = useState('none')
  const [rows, setRows] = useState({})
  useEffect(() => {
    const cookie = Cookies.get('token')
    if (!cookie) {
      dispatch(LogoutAction())
    }
    const updateTableInfo = async () => {
      const startDate = dayjs.utc(pickerDate[0]).toJSON()
      const endDate = dayjs.utc(pickerDate[1]).toJSON()
      if (endDate) {
        const response = await getTicketsChartsInfo('ru', startDate, endDate)
        setRows(() => response)
      }
    }
    updateTableInfo()
  }, [pickerDate])

  const handleDays = days => {
    setPickerDate(() => [dayjs.utc().subtract(days, 'day'), dayjs.utc()])
  }

  const isDaysAmount = days => {
    return pickerDate[1].diff(pickerDate[0], 'day') === days
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={classes.root}>
          <div className={classes.selectors}>
            <div>
              <Typography variant='h6' style={{ margin: '20px 0' }}>
                Please select range for analytics:
              </Typography>
              <DateRangePicker
                className={classes.datePicker}
                value={pickerDate}
                onChange={newValue => {
                  setPickerDate(newValue)
                  setSelectedEvent(() => 'none')
                }}
              />
            </div>
            <div>
              <Typography variant='h6' style={{ margin: '20px 0' }}>
                Or select predefined range:
              </Typography>
              <div>
                <Button
                  variant={isDaysAmount(3) ? 'outlined' : 'contained'}
                  onClick={() => handleDays(3)}
                  disabled={isDaysAmount(3)}
                  style={{ margin: '0 5px' }}
                >
                  Last 3 days
                </Button>
                <Button
                  variant={isDaysAmount(7) ? 'outlined' : 'contained'}
                  disabled={isDaysAmount(7)}
                  onClick={() => handleDays(7)}
                  style={{ margin: '0 5px' }}
                >
                  Last week
                </Button>
                <Button
                  variant={isDaysAmount(30) ? 'outlined' : 'contained'}
                  disabled={isDaysAmount(30)}
                  onClick={() => handleDays(30)}
                  style={{ margin: '0 5px' }}
                >
                  Last month
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.cards}>
            <Card
              displayData={`${Object.values(rows).reduce(
                (acc, event) => acc + event.totalRevenue,
                0
              )}`}
              displayText={'Total Revenue'}
            />
            <Card
              displayData={`${Object.values(rows).reduce(
                (acc, event) => acc + event.totalTickets,
                0
              )}`}
              displayText={'Total Tickets'}
            />
            <Card
              displayData={`${Object.values(rows).reduce(
                (acc, event) => acc + event.paidTickets,
                0
              )}`}
              displayText={'Paid Tickets'}
            />
            <Card
              displayData={`${Object.values(rows).reduce(
                (acc, event) => acc + event.unpaidTickets,
                0
              )}`}
              displayText={'Free Tickets'}
            />
            <Card
              displayData={`${Object.values(rows).reduce(
                (acc, event) => acc + event.freeTickets,
                0
              )}`}
              displayText={'Unpaid Tickets'}
            />
          </div>
          <div className={classes.charts}>
            <TicketSalesChart data={rows} />
            <SalesByZoneChart eventData={rows} />
            <PaidVsUnpaidTicketsChart eventData={rows} />
          </div>
          <Typography variant='h6' style={{ margin: '20px 0' }}>
            Please select event for individual analytics (within selected
            range):
          </Typography>
          <Select
            value={selectedEvent}
            className={classes.datePicker}
            label='Search by'
            onChange={event =>
              setSelectedEvent(() => {
                return event.target.value
              })
            }
          >
            <MenuItem value='none'>No event selected</MenuItem>
            {Object.keys(rows).length > 0 &&
              Object.keys(rows).map(eventId => (
                <MenuItem value={eventId}>{rows[eventId].name}</MenuItem>
              ))}
          </Select>
          {Object.keys(rows).length > 0 && selectedEvent !== 'none' && (
            <div key={selectedEvent}>
              <Typography variant='h6'>{rows[selectedEvent].name}</Typography>{' '}
              <div className={classes.cards}>
                <Card
                  displayData={`${rows[selectedEvent].totalRevenue} лв`}
                  displayText={'Total Revenue'}
                />
                <Card
                  displayData={rows[selectedEvent].totalTickets}
                  displayText={'Total Tickets'}
                />
                <Card
                  displayData={rows[selectedEvent].paidTickets}
                  displayText={'Paid Tickets'}
                />
                <Card
                  displayData={rows[selectedEvent].unpaidTickets}
                  displayText={'Unpaid Tickets'}
                />
              </div>
              <div className={classes.charts}>
                <TicketSalesOverTimeChart eventData={rows[selectedEvent]} />
                <TicketSalesHeatmap eventData={rows[selectedEvent]} />
              </div>
            </div>
          )}
        </div>
      </LocalizationProvider>
    </>
  )
}

export default TicketsCharts
