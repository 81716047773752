import HomePage from '../../containers/homePage/Home'
import EventComponent from '../../containers/homePage/EventComponent'
import NotFound from '../not_found/NotFound'
import InfoPage from '../../containers/tickets'
import SingleTicket from '../../containers/tickets/SingleTicket'
import Login from '../../containers/loginPage/Login'
import { authRoles } from '../../helpers/protectedRoute'

export const Routes = [
  {
    name: 'HOME',
    tabName: 'События - Standup Шенген',
    usersCanSee: authRoles.all,
    type: 'main',
    path: '/',
    Component: HomePage
  },
  {
    name: 'SCAN',
    tabName: 'Ticket Scan',
    usersCanSee: authRoles.loggedIn,
    type: 'main',
    path: '/scan',
    Component: InfoPage
  },
  {
    name: 'TICKETS',
    tabName: 'Билеты Standup Шенген',
    usersCanSee: authRoles.loggedIn,
    type: 'main',
    path: '/tickets',
    Component: InfoPage
  },
  {
    name: 'ANALYTICS',
    tabName: 'Аналитика Standup Шенген',
    usersCanSee: authRoles.loggedIn,
    type: 'main',
    path: '/analytics',
    Component: InfoPage
  },
  {
    name: 'EVENT',
    tabName: 'Ивенты Standup Шенген',
    usersCanSee: authRoles.all,
    type: 'main',
    path: '/events/:slug',
    Component: EventComponent
  },
  {
    name: 'LOGIN',
    tabName: 'Login',
    usersCanSee: authRoles.all,
    type: 'main',
    path: '/login',
    Component: Login
  },
  {
    name: 'SINGLE_TICKET',
    tabName: 'Информация о билете',
    usersCanSee: authRoles.all,
    type: 'main',
    path: '/tickets/:slug',
    Component: SingleTicket
  },
  {
    name: 'Не найдено',
    type: 'notInTheMenu',
    usersCanSee: authRoles.all,
    path: '*',
    Component: NotFound
  }
]
