import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/logoSmall.png'

import { getLimitedEventInfo, bookTicket } from '../../requests'
import {
  setNotificationMessage,
  setNotificationSeverity,
  setShowNotificationMessage
} from '../../components/notifications/store/notificationActions'
import { isDateBeforeToday } from '../../helpers/date'
import Footer from './Footer'

import OrderFirstStep from '../../components/event/OrderFirstStep'
import OrderSecondStep from '../../components/event/OrderSecondStep'
import OrderMoreTickets from '../../components/event/OrderMoreTickets'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '800px',
    margin: 'auto',
    paddingBottom: '70px',
    paddingTop: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '90px',
      paddingTop: '90px'
    }
  },
  poster: {
    width: '100%',
    maxHeight: '450px'
  },
  blurredPoster: {
    width: '100%',
    maxHeight: '450px',
    filter: 'blur(4px)'
  },
  goBack: {
    width: '100%',
    padding: '8px',
    placeSelf: 'flex-start',
    position: 'fixed',
    zIndex: 20,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0 0 5px 5px',
    top: 0,
    margin: 'auto',
    left: 0,
    right: 0,
    maxWidth: '800px'
  },
  goBackHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  goBackContent: {
    paddingTop: '10px',
    display: 'flex'
  },
  centeredInfo: {
    display: 'flex',
    gap: '10px',
    margin: '5px 0 5px 0 !important'
  },
  info: {
    position: 'sticky',
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    width: '100%',
    padding: '10px',
    placeSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      border: `none`,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  },
  pastEvent: {
    color: theme.palette.secondary.main,
    padding: '10px',
    textAlign: 'center'
  }
}))

const EventComponent = props => {
  const classes = useStyles()
  const [rows, setRows] = useState({})
  const [eventId, setEventId] = useState(null)
  const [eventPostponed, setEventPostponed] = useState(false)
  const [totalSum, setSum] = useState(0)
  const [ticketAmount, setTicketAmount] = useState(0)
  const [ticketOrdered, setTicketOrdered] = useState(false)
  const [eventIsInPast, setEventIsInPast] = useState(false)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [promocodeID, setPromocodeID] = useState(null)
  const [isFreePromo, setIsFreePromo] = useState(false)
  const [ticketsAmount, setTicketsAmount] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  async function fetchData () {
    try {
      const data = await getLimitedEventInfo(props.match.params.slug)
      setRows(() => data.attributes)
      setEventIsInPast(
        () => !isDateBeforeToday(new Date(data.attributes?.date))
      )
      setEventPostponed(() => data.attributes?.postponed)
      setEventId(() => data.id)
    } catch {
      history.push(`/`)
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.match.params.slug])

  const formik = useFormik({
    initialValues: {
      consent: false,
      holder_email: '',
      ticket_holder: '',
      holder_phone: '',
      nickname: '',
      promocode: ''
    },
    validationSchema: Yup.object().shape({
      holder_email: Yup.string()
        .email('Введите правильную почту')
        .required('Почта обязательна'),
      ticket_holder: Yup.string().required('Имя обязательно'),
      holder_phone: Yup.string().required('Номер обязателен'),
      nickname: Yup.string()
    })
  })
  const { values, touched, errors, handleChange, handleBlur, isValid, dirty } =
    formik

  const handleClick = zones => {
    if (zones.some(zone => zone.zone === 'FREE')) {
      setSum(() => 1)
      setIsFreePromo(() => true)
      return
    }
    let sum = 0
    zones.forEach(el => {
      sum += ticketsAmount[el.zone]
        ? ticketsAmount[el.zone] * (el.price - discountAmount)
        : 0
    })
    setSum(() => sum)
  }
  const addToValue = zone => {
    setTicketsAmount({
      ...ticketsAmount,
      [zone]: (ticketsAmount[zone] || 0) + 1
    })
  }
  const subtractFromValue = zone => {
    if (ticketsAmount[zone] > 0) {
      setTicketsAmount({ ...ticketsAmount, [zone]: ticketsAmount[zone] - 1 })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setTicketOrdered(() => true)

    const phoneNumber = values.holder_phone
      .trim()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/ /g, '')

    for (const [zone, ticketCount] of Object.entries(ticketsAmount)) {
      if (ticketCount === 0) {
        continue
      }
      const ticketsZone = rows?.zone.find(el => el.zone === zone)
      if (ticketCount > ticketsZone.tickets_avaliable) {
        dispatch(
          setNotificationMessage(
            'Упс, похоже произошла ошибка, пожалуйста свяжитесь с нами'
          )
        )
        dispatch(setShowNotificationMessage(true))
        return
      }

      const tickets = {
        ...values,
        count: ticketCount,
        holder_phone: phoneNumber,
        zone: zone,
        event: eventId,
        promocode: promocodeID
      }

      try {
        await bookTicket(tickets)
      } catch (error) {
        console.log(error)
        setTicketOrdered(() => false)
        dispatch(
          setNotificationMessage(
            'Упс, похоже произошла ошибка, пожалуйста свяжитесь с нами'
          )
        )
        dispatch(setShowNotificationMessage(true))
        return
      }
    }
    const ticketsSum = Object.values(ticketsAmount).reduce(
      (acc, cur) => acc + cur,
      0
    )
    setTicketAmount(prev => prev + ticketsSum)
    dispatch(setNotificationMessage('Ваша бронь была отправлена'))
    dispatch(setShowNotificationMessage(true))
    dispatch(setNotificationSeverity('success'))
    setTicketsAmount({})
    setSum(() => 0)
    fetchData()
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.goBack}>
          <div
            onClick={() => history.push(`/`)}
            className={classes.goBackHolder}
          >
            <span className={classes.goBackContent}>
              <ArrowBackIcon /> Ко всем событиям
            </span>
            <img src={logo} alt='logo' width='50px' />
          </div>
        </div>
        <img
          src={`${process.env.REACT_APP_API_URL}${rows?.poster?.data?.attributes?.url}`}
          alt='logo'
          className={eventPostponed ? classes.blurredPoster : classes.poster}
        />
        {eventPostponed ? (
          <>
            <Typography variant='h6' className={classes.pastEvent}>
              Мероприятие было перенесено. Для информации свяжитесь с нами.
            </Typography>
          </>
        ) : (
          <Typography
            variant='h5'
            style={{ whiteSpace: 'pre-line', padding: '10px' }}
          >
            {rows.name}
          </Typography>
        )}
        <div className={classes.info}>
          <Typography variant='body2' className={classes.centeredInfo}>
            <CalendarMonthIcon fontSize='small' />{' '}
            {new Date(rows?.date).toLocaleString('ru-ru', {
              dateStyle: 'full',
              timeStyle: 'short',
              timeZone: 'Europe/Sofia'
            })}
          </Typography>
          <Typography variant='body2' className={classes.centeredInfo}>
            <LocationOnIcon fontSize='small' /> {rows?.street}
          </Typography>
        </div>
        {eventIsInPast && (
          <Typography variant='h6' className={classes.pastEvent}>
            Мероприятие уже прошло
          </Typography>
        )}
        <Typography
          variant='subheading'
          style={{ whiteSpace: 'pre-line', padding: '10px' }}
        >
          {rows?.description}
        </Typography>
        {eventPostponed ? (
          <></>
        ) : (
          <>
            {ticketOrdered ? (
              <OrderMoreTickets
                setTicketOrdered={setTicketOrdered}
                ticketAmount={ticketAmount}
              />
            ) : (
              <>
                {totalSum === 0 && (
                  <OrderFirstStep
                    rows={rows}
                    eventIsInPast={eventIsInPast}
                    addToValue={addToValue}
                    ticketsAmount={ticketsAmount}
                    subtractFromValue={subtractFromValue}
                    handleClick={handleClick}
                  />
                )}
                {totalSum !== 0 && (
                  <OrderSecondStep
                    rows={rows}
                    eventId={eventId}
                    setSum={setSum}
                    setPromocodeID={setPromocodeID}
                    isFreePromo={isFreePromo}
                    setIsFreePromo={setIsFreePromo}
                    ticketsAmount={ticketsAmount}
                    totalSum={totalSum}
                    discountAmount={discountAmount}
                    setDiscountAmount={setDiscountAmount}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    values={values}
                    handleChange={handleChange}
                    isValid={isValid}
                    dirty={dirty}
                    loading={ticketOrdered}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default EventComponent
