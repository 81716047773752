import React, { useState, useEffect } from 'react'
import { QrScanner } from '@yudiel/react-qr-scanner'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import { getTicketInfo, updateTicketInfo } from '../../requests'
import { LogoutAction } from '../../requests/store/LoginActions'
import {
  setNotificationMessage,
  setShowNotificationMessage
} from '../../components/notifications/store/notificationActions'

const useStyles = makeStyles(theme => ({
  fullPage: {
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.primary.light
  },
  qrHolder: {
    width: '500px',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      alignSelf: 'auto'
    }
  },
  qrInfo: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'auto'
    }
    // maxWidth: '250px'
  }
}))
const QRScanner = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [id, setId] = useState(null)

  const dispatch = useDispatch()
  useEffect(() => {
    const cookie = Cookies.get('token')
    if (!cookie) {
      dispatch(LogoutAction())
    }
  }, [])

  const decode = async result => {
    const uuid = result.substring(result.lastIndexOf('/') + 1)
    try {
      const response = await getTicketInfo(uuid)
      if (response.attributes) {
        setIsLoading(() => true)
        setData(() => response.attributes)
        setId(() => response.id)
      }
    } catch (err) {
      dispatch(
        setNotificationMessage(
          err.message === '404' ? 'No ticket found' : err.message
        )
      )
      dispatch(setShowNotificationMessage(true))
    }

    return
  }

  const handleClick = async event => {
    await updateTicketInfo(id)

    setIsLoading(() => false)
    setData(() => {})
  }

  return (
    <>
      <div className={classes.fullPage}>
        <div className={classes.qrInfo}>
        <Typography variant='body2'>REF: {data?.reference_number}</Typography>

          <Typography variant='h5'>Name: {data?.ticket_holder}</Typography>
          <Typography variant='h5'>Zone: {data?.zone}</Typography>
          {data?.ticket_holder && !data.paid && (
            <>
              <Typography variant='h6'>Status: Not Paid</Typography>
              <Typography variant='h4'>{data.price} lv</Typography>
              <Button variant='contained' onClick={handleClick}>
                Pay
              </Button>
            </>
          )}
          {data?.ticket_holder && data.paid && (
            <>
              <Typography variant='h6'>Status: Paid</Typography>
              <Typography variant='h6'>
                Scanned Before:{' '}
                {data.Scanned ? 'Already Scanned' : 'Not Scanned'}
              </Typography>
              <Button variant='contained' onClick={handleClick}>
                Next
              </Button>
            </>
          )}
        </div>
        <div className={classes.qrHolder}>
          <QrScanner
            scanDelay={1000}
            hideCount={false}
            stopDecoding={isLoading}
            onDecode={result => decode(result)}
            onError={error => console.log(error?.message)}
          />
        </div>
      </div>
    </>
  )
}

export default QRScanner
