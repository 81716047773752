import * as constants from './GetContentActions'

const initialState = {
  content: [],
  loading: true,
  error: false
}

export const getContent = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.setContent:
      return { ...state, content: action.payload }
    case constants.setLoading:
      return { ...state, loading: true }
    case constants.setLoadingError:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}
