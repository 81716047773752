import React from 'react'
import {
  ScatterChart,
  Scatter,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts'
import { GRAPHS_WIDTH, MOBILE_GRAPHS_WIDTH, GRAPHS_HEIGHT } from '../Constants'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const TicketSalesHeatmap = ({ eventData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  if (!eventData) {
    return <Typography variant='h6'>No data available</Typography>
  }

  const chartData = Object.keys(eventData.dateAnalytics).map(date => ({
    date,
    value: eventData.dateAnalytics[date].totalTickets || 0
  }))

  return (
    <div>
      <Typography variant='h6'>Ticket Sales Heatmap</Typography>{' '}
      <ScatterChart
        width={isMobile ? GRAPHS_WIDTH : MOBILE_GRAPHS_WIDTH}
        height={GRAPHS_HEIGHT}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' angle={45} height={60} type='category' />
        <YAxis dataKey='value' />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name={eventData.name} data={chartData} fill='#8884d8' />
      </ScatterChart>
    </div>
  )
}

export default TicketSalesHeatmap
