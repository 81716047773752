import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { themesConfig } from '../themes/main'
import { Routes } from '../components/routes'
import { setCurrentPage } from '../components/navbar/store/current_page/CurrentPageActions'
import { PageNotifications } from '../components/notifications/notificationsService'
import '../themes/Main.scss'
import { ProtectedRoute } from '../helpers/protectedRoute'

const App = ({ _, history }) => {
  const state = useSelector((state) => ({
    content: state.getContent.content,
    showNotificationMessage: state.showNotification.showNotificationMessage
  }))
  const theme = createTheme(themesConfig)
  const dispatch = useDispatch()

  useEffect(() => {
    let pathname
    try {
      pathname = Routes.filter(el => el.path === history.location.pathname)[0]
        .tabName
    } catch {
      pathname = 'Стендап клуб "Шенген'
    }
    document.title = pathname
    dispatch(setCurrentPage(history.location.pathname))
  }, [history.location.pathname])

  const routeComponents = Routes.map(
    ({ path, usersCanSee, Component }, key) => (
      <ProtectedRoute
        exact
        users={usersCanSee}
        path={path}
        component={Component}
        key={key}
      />
    )
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {state.showNotificationMessage && <PageNotifications />}
      <Switch>{routeComponents}</Switch>
    </ThemeProvider>
  )
}

export default withRouter(App)
