import React from 'react'
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { GRAPHS_WIDTH, MOBILE_GRAPHS_WIDTH, GRAPHS_HEIGHT } from '../Constants'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const SalesByZoneChart = ({ eventData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  if (Object.keys(eventData).length === 0) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', margin: '0 20px' }}
      >
        <Typography variant='h6'>Sales By Zone (Combined)</Typography>
        <div>No data available</div>
      </div>
    )
  }

  const combinedSalesByZone = {}
  Object.values(eventData).forEach(event => {
    Object.entries(event.salesByZone).forEach(([zone, data]) => {
      if (!combinedSalesByZone[zone]) {
        combinedSalesByZone[zone] = {
          name: zone,
          totalTickets: data.totalTickets,
          totalRevenue: data.totalRevenue
        }
      } else {
        combinedSalesByZone[zone].totalTickets += data.totalTickets
        combinedSalesByZone[zone].totalRevenue += data.totalRevenue
      }
    })
  })

  const chartData = Object.values(combinedSalesByZone)

  return (
    <div>
      <Typography variant='h6'>Sales By Zone (Combined)</Typography>
      <ResponsiveContainer
        width={isMobile ? GRAPHS_WIDTH : MOBILE_GRAPHS_WIDTH}
        height={GRAPHS_HEIGHT}
      >
        <PieChart>
          <Pie
            dataKey='totalTickets'
            data={chartData}
            cx='50%'
            cy='50%'
            outerRadius={100}
            fill='#8884d8'
            label
          />
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SalesByZoneChart
