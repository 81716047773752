import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import { GRAPHS_WIDTH, MOBILE_GRAPHS_WIDTH, GRAPHS_HEIGHT } from '../Constants'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';


const PaidVsUnpaidTicketsChart = ({ eventData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))

  if (Object.keys(eventData).length === 0) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', margin: '0 20px' }}
      >
        <Typography variant='h6'>Paid vs Unpaid Tickets by Event</Typography>
        <div>No data available</div>
      </div>
    )
  }
  const chartData = Object.keys(eventData).map(eventId => ({
    name: eventData[eventId].name,
    paidTickets: eventData[eventId].paidTickets,
    unpaidTickets: eventData[eventId].unpaidTickets
  }))

  return (
    <div>
      <Typography variant='h6'>Paid vs Unpaid Tickets by Event</Typography>
      <BarChart
        width={isMobile ? GRAPHS_WIDTH : MOBILE_GRAPHS_WIDTH}
        height={GRAPHS_HEIGHT}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='paidTickets' stackId='a' fill='#8884d8' />
        <Bar dataKey='unpaidTickets' stackId='a' fill='#82ca9d' />
      </BarChart>
    </div>
  )
}

export default PaidVsUnpaidTicketsChart
